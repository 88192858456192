<template>
    <v-data-table
        dense
        :headers="headers"
        :items="items"
        no-data-text="Sin Contenido"
        no-results-text="Sin resultados encontrados"
        item-key="id"
        class="elevation-10"
        disable-pagination
        hide-default-footer
    >
        <template v-slot:[`item.actions`]="{ item }">
            <v-icon
                medium
                color="warning"
                class="mr-2"
                @click="$emit('EditAsiento', item)"
            >
            mdi-pencil
            </v-icon>
            <v-icon
                medium
                color="red"
                @click="$emit('DeleteAsiento', item)"
            >
            mdi-delete
            </v-icon>
        </template>
        <template v-if="addChipStatus" v-slot:[`item.empresa`]="{ item }">
            <v-chip class="ma-2" :color="colorChip(item)" dark>{{ item.titulo_empresa }}</v-chip>
        </template>

        <template v-if="addChipTratamiento" v-slot:[`item.tratamiento`]="{ item }">
            <v-chip class="ma-2" :color="colorChipTratamiento(item)" outlined dark>{{item.porcentaje}}% </v-chip>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "TableCrud",
    props: {
        headers: {
            type: Array,
            default: () => [],
        },
        items: {
            type: Array,
            default: () => [],
        },
        addChipStatus: {
            type: Boolean,
            default: () => false,
        },
        addChipTratamiento: {
            type: Boolean,
            default: () => false,
        },
    },
    methods: {
    colorChip(item) {
      switch (item.id_empresa) {
        case null:
          return "red";
        // case !null:
        //   return "green";
        // // break;
        default:
          return "green";
      }
    },
    colorChipTratamiento(item){
        switch (item.id_tratamiento){
            case null:
                return "white";
            // break;
            default:
                return "green";
        }
    }
  },
}
</script>