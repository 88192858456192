<template>
  <v-dialog v-model="openDialog" max-width="1200px" persistent>
    <div class="d-flex flex-row-reverse">
      <v-btn class="mb-1" fab x-small color="red" dark @click="closeDialog"
        >X
      </v-btn>
    </div>
    <v-stepper v-model="ventana">
      <v-stepper-header>
        <v-stepper-step step="1" editable> Tipo de Cuenta </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" editable> Datos de Cuenta </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-card class="mx-auto" width="300" elevation="6">
                    <v-list rounded>
                      <v-list-item>
                        <v-list-item-title>Activos</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-group :value="false" no-action>
                        <template v-slot:activator>
                          <v-list-item>Circulante</v-list-item>
                        </template>
                        <v-list-item
                          v-for="([title], i) in activos"
                          :key="i"
                          link
                        >
                          <v-list-item-content
                            @click="
                              agregarCuenta(
                                110 + i,
                                'Activos/ Circulante/ ' + title
                              )
                            "
                            v-text="title"
                          ></v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item
                          @click="
                            agregarCuenta(120, 'Activos/ Fijos-Inmoviles')
                          "
                          >Fijos-Inmoviles</v-list-item
                        >
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item
                          @click="agregarCuenta(130, 'Activos/ Otros Activos')"
                          >Otros Activos</v-list-item
                        >
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-card class="mx-auto" width="300" elevation="6">
                    <v-list rounded>
                      <v-list-item>
                        <v-list-item-title>Pasivos</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-group :value="false" no-action>
                        <template v-slot:activator>
                          <v-list-item>Circulante</v-list-item>
                        </template>
                        <v-list-item
                          v-for="([title], i) in pasivosCirculante"
                          :key="i"
                          link
                        >
                          <v-list-item-content
                            @click="
                              agregarCuenta(
                                210 + i,
                                'Pasivos/ Circulante/ ' + title
                              )
                            "
                            v-text="title"
                          ></v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                      <v-divider></v-divider>
                      <v-list-group :value="false" no-action>
                        <template v-slot:activator>
                          <v-list-item>Largo Plazo</v-list-item>
                        </template>
                        <v-list-item
                          v-for="([title], i) in pasivosLargoPlazo"
                          :key="i"
                          link
                        >
                          <v-list-item-content
                            @click="
                              agregarCuenta(
                                220 + i,
                                'Pasivos/ Largo Plazo/ ' + title
                              )
                            "
                            v-text="title"
                          ></v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item
                          @click="
                            agregarCuenta(230, 'Pasivos/ No Exige Patrimonio')
                          "
                          >No Exige Patrimonio</v-list-item
                        >
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-card class="mx-auto" width="300" elevation="6">
                    <v-list rounded>
                      <v-list-item>
                        <v-list-item-title>Perdidas</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item
                          @click="
                            agregarCuenta(310, 'Perdidas/ Costos Directos')
                          "
                          >Costos Directos</v-list-item
                        >
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item
                          @click="
                            agregarCuenta(320, 'Perdidas/ Costos Indirectos')
                          "
                          >Costos Indirectos</v-list-item
                        >
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4" lg="3">
                  <v-card class="mx-auto" width="300" elevation="6">
                    <v-list rounded>
                      <v-list-item>
                        <v-list-item-title>Ganancias</v-list-item-title>
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item
                          @click="
                            agregarCuenta(410, 'Ganancias/ Ventas de Giro')
                          "
                          >Ventas de Giro</v-list-item
                        >
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item
                          @click="
                            agregarCuenta(420, 'Ganancias/ Otras Ventas')
                          "
                          >Otras Ventas</v-list-item
                        >
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card>
            <v-card-title primary-title>
              {{ tipoCuenta }}
            </v-card-title>
            <v-card-text>
              <v-form ref="refFormCuentas">
                <v-row>
                  <v-col class="mt-6" cols="12" sm="6" md="4" lg="4">
                    <v-text-field
                      v-model="nombre_cuenta"
                      name="name"
                      label="Nombre Cuenta"
                      id="id"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" lg="3">
                    <v-radio-group v-model="seleccion" column>
                      <v-radio
                        label="Para todas las empresas"
                        color="red darken-3"
                        value="1"
                      ></v-radio>
                      <v-radio
                        label="Para esta empresa"
                        color="indigo"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col>
                    <v-switch
                      label="Con Tratamiento"
                      v-model="switch1"
                    ></v-switch>
                  </v-col>
                  <v-col class="mt-5" v-if="!switch1">
                    <v-btn color="success" @click="guardarCuenta"
                      >Guardar</v-btn
                    >
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row v-if="switch1">
                  <v-col class="mt-6" cols="12" sm="6" md="4" lg="3">
                    <v-autocomplete
                      v-model="tratamiento"
                      :items="items"
                      item-value="id"
                      item-text="nombre_tratamiento"
                      outlined
                      return-object
                      dense
                      label="Tratamiento"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="4" md="4" lg="2">
                    <v-radio-group v-model="debe_haber" column>
                      <v-radio label="Debe" color="success" value="1"></v-radio>
                      <v-radio
                        label="Haber"
                        color="success"
                        value="2"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col class="mt-6" cols="12" sm="6" md="3" lg="2">
                    <v-text-field
                      v-model="porcentaje"
                      outlined
                      append-icon="mdi-percent"
                      dense
                      name="name"
                      label="Porcentaje"
                      id="id"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col class="mt-6" cols="12" sm="6" md="4" lg="3">
                    <v-autocomplete
                      v-model="impuestos"
                      :items="items_impuesto"
                      item-text="nombre"
                      item-value="id"
                      return-object
                      outlined
                      dense
                      label="Impuestos"
                    ></v-autocomplete>
                  </v-col>
                  <v-col class="mt-6">
                    <v-btn color="success" @click="guardarCuentaTratamiento"
                      >Guardar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>

<script>
import CuentasImputablesService from "../../../service/api/cuentasImputables/index";
import TratamientoService from "../../../service/api/tratamiento/index";
import ImpuestoService from "../../../service/api/impuesto/index";

export default {
  data() {
    return {
      porcentaje: 0,
      debe_haber: "",
      tratamiento: [],
      datos: [],
      openDialog: false,
      activos: [["Circulante"], ["Disponible"], ["Impuestos"], ["Deudores"]],
      pasivosLargoPlazo: [["Largo Plazo"], ["Inst Financiera"]],
      pasivosCirculante: [
        ["Circulante"],
        ["Acreedores"],
        ["Impuestos"],
        ["Inst Financiera"],
      ],
      items: [],
      impuestos: null,
      items_impuesto: [],
      switch1: false,
      switch2: true,
      ventana: 1,
      nombre_cuenta: "",
      tipoCuenta: "",
      seleccion: "",
      numeroCuenta: "",
    };
  },
  computed: {
    validateForm() {
      return this.$refs.refFormFactura.validate();
    },
    resetForm() {
      return this.$refs.refFormCuentas.reset();
    },
  },
  mounted() {
    this.getTratamientos();
    this.getImpuestos();
  },
  methods: {
    agregarCuenta(numero, tipoCuenta) {
      this.numeroCuenta = numero;
      this.tipoCuenta = tipoCuenta;
      this.ventana = 2;
    },
    async guardarCuenta() {
      let id_empresa = 0;
      if (this.seleccion == 1) {
        id_empresa = null;
      } else {
        id_empresa = this.$store.state.empresa;
      }
      this.datos.push({
        tipo_cuenta: this.tipoCuenta,
        cod_cuenta: this.numeroCuenta,
        nombre_cuenta: this.nombre_cuenta,
        id_empresa: id_empresa,
        id_tratamiento: null,
      });
      let response = await CuentasImputablesService.create(this.datos[0]);

      if (response.status === 201) {
        this.closeDialog();
        this.$emit("successResponse", this.numeroCuenta);
      } else {
        this.closeDialog();
        this.$emit("errorResponse", response);
      }
      this.datos = [];
    },
    async guardarCuentaTratamiento() {
       let id_empresa = 0;
      if (this.seleccion == 1) {
        id_empresa = null;
      } else {
        id_empresa = this.$store.state.empresa;
      }
      this.datos.push({
        tipo_cuenta: this.tipoCuenta,
        cod_cuenta: this.numeroCuenta,
        nombre_cuenta: this.nombre_cuenta,
        id_empresa: id_empresa,
        id_tratamiento: this.tratamiento.id,
        debe_haber: this.debe_haber,
        porcentaje: this.porcentaje,
        impuesto: this.impuestos.id,
      });

      let response = await CuentasImputablesService.create(this.datos[0]);

      if (response.status === 201) {
        this.closeDialog();
        this.$emit("successResponse", this.numeroCuenta);
      } else {
        this.closeDialog();
        this.$emit("errorResponse", response);
      }
      this.datos = [];
      this.closeDialog();
    },
    async getTratamientos() {
      let response = await TratamientoService.fetchAll();
      this.items = response;
    },
    async getImpuestos() {
      let response = await ImpuestoService.fetchAll();
      this.items_impuesto = response;
    },
    closeDialog() {
      this.openDialog = false;
      this.resetForm;
      this.ventana = 1;
    },
  },
};
</script>