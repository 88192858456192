<template>
  <div>
    <v-dialog v-model="openDialog" max-width="1200px" persistent>
      <div class="d-flex flex-row-reverse">
        <v-btn class="mb-1" fab x-small color="red" dark @click="closeDialog"
          >X
        </v-btn>
      </div>
      <v-card>
        <v-card-text>
          <v-form ref="refFormEditCuentas">
            <v-row>
              <v-col class="mt-6" cols="12" sm="6" md="4" lg="4">
                <v-text-field
                  v-model="nombre_cuenta"
                  name="name"
                  label="Nombre Cuenta"
                  id="id"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-radio-group v-model="seleccion" column>
                  <v-radio
                    label="Para todas las empresas"
                    color="red darken-3"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    label="Para esta empresa"
                    color="indigo"
                    :value="2"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col>
                <v-switch label="Con Tratamiento" v-model="switch1"></v-switch>
              </v-col>
              <v-col class="mt-5" v-if="!switch1">
                <v-btn color="warning" @click="guardarCuentaTratamiento"
                  >Editar</v-btn
                >
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row v-if="switch1">
              <v-col class="mt-6" cols="12" sm="6" md="4" lg="3">
                <v-autocomplete
                  return-object
                  v-model="tratamiento"
                  :items="items"
                  item-value="id"
                  item-text="nombre_tratamiento"
                  outlined
                  dense
                  chips
                  label="Tratamiento"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="2">
                <v-radio-group v-model="debe_haber" column>
                  <v-radio label="Debe" color="success" :value="1"></v-radio>
                  <v-radio label="Haber" color="success" :value="2"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="mt-6" cols="12" sm="6" md="4" lg="3">
                <v-text-field
                  v-model="porcentaje"
                  outlined
                  prepend-icon="%"
                  dense
                  label="Porcentaje"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col class="mt-6" cols="12" sm="6" md="4" lg="3">
                <v-autocomplete
                  return-object
                  v-model="impuestos"
                  :items="items_impuesto"
                  item-text="nombre"
                  item-value="id"
                  outlined
                  dense
                  label="Impuestos"
                ></v-autocomplete>
              </v-col>
              <v-col class="mt-6">
                <v-btn color="warning" @click="guardarCuentaTratamiento"
                  >Editar</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ImpuestoService from "../../../service/api/impuesto/index";
import TratamientoService from "../../../service/api/tratamiento/index";
import CuentasImputablesService from "../../../service/api/cuentasImputables/index";
export default {
  props: {
    DatosCuenta: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    validateForm() {
      return this.$refs.refFormEditCuentas.validate();
    },
    resetForm() {
      return this.$refs.refFormEditCuentas.reset();
    },
  },

  data() {
    return {
      items_impuesto: [],
      items: [],
      openDialog: false,
      nombre_cuenta: "",
      seleccion: 0,
      switch1: false,
      tratamiento: 0,
      debe_haber: 0,
      porcentaje: 0,
      impuestos: 0,
    };
  },
  methods: {
    getValues() {
      this.nombre_cuenta = this.DatosCuenta.nombre_cuenta;
      if (this.DatosCuenta.id_empresa) {
        this.seleccion = 2;
      } else {
        this.seleccion = 1;
      }
      if (this.DatosCuenta.id_tratamiento != null) {
        this.switch1 = true;
        this.tratamiento = this.DatosCuenta.id_tratamiento;
        this.debe_haber = this.DatosCuenta.debe_haber;
        this.porcentaje = this.DatosCuenta.porcentaje;
        this.impuestos = this.DatosCuenta.impuesto;
      } else {
        this.switch1 = false;
      }
    },
    async getTratamientos() {
      let response = await TratamientoService.fetchAll();
      this.items = response;
    },
    async getImpuesto() {
      let response = await ImpuestoService.fetchAll();
      this.items_impuesto = response;
    },

    closeDialog() {
      this.openDialog = false;
    },
    async guardarCuentaTratamiento() {
      let cuenta = {};
      cuenta.nombre_cuenta = this.nombre_cuenta;
      if (this.seleccion == 1) {
         cuenta.empresa = null;
      } else {
         cuenta.empresa = parseInt(this.$store.state.empresa);
      }
      if (this.tratamiento.id) {
        cuenta.id_tratamiento = this.tratamiento.id;
      }else{
        cuenta.id_tratamiento = null;
      }
      cuenta.debe_haber = this.debe_haber;
      cuenta.porcentaje = this.porcentaje;
      if (this.impuestos.id) {
        cuenta.impuesto = this.impuestos.id;
      } else {
         cuenta.impuesto = null;
      }
      let response = await CuentasImputablesService.update(
        cuenta,
        this.DatosCuenta.id
      );
      if (response.status === 200) {
        this.closeDialog();
        this.$emit("successResponse", this.DatosCuenta.cod_corto);
      } else {
        this.closeDialog();
        this.$emit("errorResponse", response);
      }
      this.tratamiento = [];
      this.debe_haber = 0;
      this.porcentaje = 0;
      this.impuestos = [];
    },
    // async guardarCuenta() {
    //   let cuenta = {}
    //   cuenta.nombre_cuenta = this.nombre_cuenta
    //   cuenta.empresa = this.seleccion
    //   cuenta.id_tratamiento = null
    //   cuenta.debe_haber = null
    //   cuenta.porcentaje = null
    //   let response = await CuentasImputablesService.update(cuenta, this.DatosCuenta.id)
    //   if (response.status === 200) {
    //       this.$emit("successResponse", this.DatosCuenta.cod_corto);
    //       this.closeDialog();
    //     } else {
    //       this.$emit("errorResponse", response);
    //       this.closeDialog();
    //     }
    // },
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.getTratamientos();
        this.getImpuesto();
        this.getValues();
      }
    },
  },
};
</script>