<template>
  <v-container>
      <v-card-title>
        <span>Cuentas Imputables</span>
        <v-spacer />
        <v-btn color="success" outlined rounded text @click="openAdd">
          <!-- <v-icon class="mr-2"> mdi-plus </v-icon> -->
          Agregar
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row justify="center">
          <v-expansion-panels popout hover>
            <v-expansion-panel >
              <v-expansion-panel-header>ACTIVOS</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels popout hover> 
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >CIRCULANTES</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <v-expansion-panels popout hover>
                        <v-expansion-panel>
                          <v-expansion-panel-header @click="BuscarCuenta(110)"
                            >CIRCULANTES</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <div class="text-center">
                              <v-progress-circular
                                :size="70"
                                :width="7"
                                v-if="load"
                                indeterminate
                                color="blue"
                              ></v-progress-circular>
                            </div>
                            <div v-if="!load">
                              <DataTable
                                :headers="headers"
                                :items="items"
                                addChipStatus
                                addChipTratamiento
                                @EditAsiento="editAsiento"
                                @DeleteAsiento="deleteAsiento"
                              ></DataTable>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header @click="BuscarCuenta(111)"
                            >DISPONIBLE</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <div class="text-center">
                              <v-progress-circular
                                :size="70"
                                :width="7"
                                v-if="load"
                                indeterminate
                                color="blue"
                              ></v-progress-circular>
                            </div>
                            <div v-if="!load">
                              <DataTable
                                :headers="headers"
                                :items="items"
                                addChipStatus
                                addChipTratamiento
                                @EditAsiento="editAsiento"
                                @DeleteAsiento="deleteAsiento"
                              ></DataTable>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header @click="BuscarCuenta(112)"
                            >IMPUESTOS</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <div class="text-center">
                              <v-progress-circular
                                :size="70"
                                :width="7"
                                v-if="load"
                                indeterminate
                                color="blue"
                              ></v-progress-circular>
                            </div>
                            <div v-if="!load">
                              <DataTable
                                :headers="headers"
                                :items="items"
                                addChipStatus
                                addChipTratamiento
                                @EditAsiento="editAsiento"
                                @DeleteAsiento="deleteAsiento"
                              ></DataTable>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header @click="BuscarCuenta(113)"
                            >DEUDORES</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <div class="text-center">
                              <v-progress-circular
                                :size="70"
                                :width="7"
                                v-if="load"
                                indeterminate
                                color="blue"
                              ></v-progress-circular>
                            </div>
                            <div v-if="!load">
                              <DataTable
                                :headers="headers"
                                :items="items"
                                addChipStatus
                                addChipTratamiento
                                @EditAsiento="editAsiento"
                                @DeleteAsiento="deleteAsiento"
                              ></DataTable>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header @click="BuscarCuenta(120)"
                      >FIJOS-INMOVILES</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <div class="text-center">
                        <v-progress-circular
                          :size="70"
                          :width="7"
                          v-if="load"
                          indeterminate
                          color="blue"
                        ></v-progress-circular>
                      </div>
                      <div v-if="!load">
                        <DataTable
                          :headers="headers"
                          :items="items"
                          addChipStatus
                          addChipTratamiento
                          @EditAsiento="editAsiento"
                          @DeleteAsiento="deleteAsiento"
                        ></DataTable>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header @click="BuscarCuenta(130)"
                      >OTROS ACTIVOS</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <div class="text-center">
                        <v-progress-circular
                          :size="70"
                          :width="7"
                          v-if="load"
                          indeterminate
                          color="blue"
                        ></v-progress-circular>
                      </div>
                      <div v-if="!load">
                        <DataTable
                          :headers="headers"
                          :items="items"
                          addChipStatus
                          addChipTratamiento
                          @EditAsiento="editAsiento"
                          @DeleteAsiento="deleteAsiento"
                        ></DataTable>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>PASIVOS</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels popout hover>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >CIRCULANTES</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <v-expansion-panels popout hover>
                        <v-expansion-panel>
                          <v-expansion-panel-header @click="BuscarCuenta(210)"
                            >CIRCULANTES</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <div class="text-center">
                              <v-progress-circular
                                :size="70"
                                :width="7"
                                v-if="load"
                                indeterminate
                                color="blue"
                              ></v-progress-circular>
                            </div>
                            <div v-if="!load">
                              <DataTable
                                :headers="headers"
                                :items="items"
                                addChipStatus
                                addChipTratamiento
                                @EditAsiento="editAsiento"
                                @DeleteAsiento="deleteAsiento"
                              ></DataTable>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header @click="BuscarCuenta(211)"
                            >ACREEDORES</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <div class="text-center">
                              <v-progress-circular
                                :size="70"
                                :width="7"
                                v-if="load"
                                indeterminate
                                color="blue"
                              ></v-progress-circular>
                            </div>
                            <div v-if="!load">
                              <DataTable
                                :headers="headers"
                                :items="items"
                                addChipStatus
                                addChipTratamiento
                                @EditAsiento="editAsiento"
                                @DeleteAsiento="deleteAsiento"
                              ></DataTable>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header @click="BuscarCuenta(212)"
                            >IMPUESTOS</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <div class="text-center">
                              <v-progress-circular
                                :size="70"
                                :width="7"
                                v-if="load"
                                indeterminate
                                color="blue"
                              ></v-progress-circular>
                            </div>
                            <div v-if="!load">
                              <DataTable
                                :headers="headers"
                                :items="items"
                                addChipStatus
                                addChipTratamiento
                                @EditAsiento="editAsiento"
                                @DeleteAsiento="deleteAsiento"
                              ></DataTable>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header @click="BuscarCuenta(213)"
                            >INSTANCIA FINANCIERA</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <div class="text-center">
                              <v-progress-circular
                                :size="70"
                                :width="7"
                                v-if="load"
                                indeterminate
                                color="blue"
                              ></v-progress-circular>
                            </div>
                            <div v-if="!load">
                              <DataTable
                                :headers="headers"
                                :items="items"
                                addChipStatus
                                addChipTratamiento
                                @EditAsiento="editAsiento"
                                @DeleteAsiento="deleteAsiento"
                              ></DataTable>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                      >LARGO PLAZO</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <v-expansion-panels popout hover>
                        <v-expansion-panel>
                          <v-expansion-panel-header @click="BuscarCuenta(220)"
                            >LARGO PLAZO</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <div class="text-center">
                              <v-progress-circular
                                :size="70"
                                :width="7"
                                v-if="load"
                                indeterminate
                                color="blue"
                              ></v-progress-circular>
                            </div>
                            <div v-if="!load">
                              <DataTable
                                :headers="headers"
                                :items="items"
                                addChipStatus
                                addChipTratamiento
                                @EditAsiento="editAsiento"
                                @DeleteAsiento="deleteAsiento"
                              ></DataTable>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                          <v-expansion-panel-header @click="BuscarCuenta(221)"
                            >INSTANCIA FINANCIERA</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <div class="text-center">
                              <v-progress-circular
                                :size="70"
                                :width="7"
                                v-if="load"
                                indeterminate
                                color="blue"
                              ></v-progress-circular>
                            </div>
                            <div v-if="!load">
                              <DataTable
                                :headers="headers"
                                :items="items"
                                addChipStatus
                                addChipTratamiento
                                @EditAsiento="editAsiento"
                                @DeleteAsiento="deleteAsiento"
                              ></DataTable>
                            </div>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header @click="BuscarCuenta(230)"
                      >NO EXIGE PATRIMONIO</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <div class="text-center">
                        <v-progress-circular
                          :size="70"
                          :width="7"
                          v-if="load"
                          indeterminate
                          color="blue"
                        ></v-progress-circular>
                      </div>
                      <div v-if="!load">
                        <DataTable
                          :headers="headers"
                          :items="items"
                          addChipStatus
                          addChipTratamiento
                          @EditAsiento="editAsiento"
                          @DeleteAsiento="deleteAsiento"
                        ></DataTable>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>PERDIDAS</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels popout hover>
                  <v-expansion-panel>
                    <v-expansion-panel-header @click="BuscarCuenta(310)"
                      >COSTOS DIRECTOS</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <div class="text-center">
                        <v-progress-circular
                          :size="70"
                          :width="7"
                          v-if="load"
                          indeterminate
                          color="blue"
                        ></v-progress-circular>
                      </div>
                      <div v-if="!load">
                        <DataTable
                          :headers="headers"
                          :items="items"
                          addChipStatus
                          addChipTratamiento
                          @EditAsiento="editAsiento"
                          @DeleteAsiento="deleteAsiento"
                        ></DataTable>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header @click="BuscarCuenta(320)"
                      >COSTOS INDIRECTOS</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <div class="text-center">
                        <v-progress-circular
                          :size="70"
                          :width="7"
                          v-if="load"
                          indeterminate
                          color="blue"
                        ></v-progress-circular>
                      </div>
                      <div v-if="!load">
                        <DataTable
                          :headers="headers"
                          :items="items"
                          addChipStatus
                          addChipTratamiento
                          @EditAsiento="editAsiento"
                          @DeleteAsiento="deleteAsiento"
                        ></DataTable>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>GANANCIAS</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-expansion-panels popout hover>
                  <v-expansion-panel>
                    <v-expansion-panel-header @click="BuscarCuenta(410)"
                      >VENTAS DE GIRO</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <div class="text-center">
                        <v-progress-circular
                          :size="70"
                          :width="7"
                          v-if="load"
                          indeterminate
                          color="blue"
                        ></v-progress-circular>
                      </div>
                      <div v-if="!load">
                        <DataTable
                          :headers="headers"
                          :items="items"
                          addChipStatus
                          addChipTratamiento
                          @EditAsiento="editAsiento"
                          @DeleteAsiento="deleteAsiento"
                        ></DataTable>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header @click="BuscarCuenta(420)"
                      >OTRAS VENTAS</v-expansion-panel-header
                    >
                    <v-expansion-panel-content>
                      <div class="text-center">
                        <v-progress-circular
                          :size="70"
                          :width="7"
                          v-if="load"
                          indeterminate
                          color="blue"
                        ></v-progress-circular>
                      </div>
                      <div v-if="!load">
                        <DataTable
                          :headers="headers"
                          :items="items"
                          addChipStatus
                          addChipTratamiento
                          @EditAsiento="editAsiento"
                          @DeleteAsiento="deleteAsiento"
                        ></DataTable>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-card-text>
    <IngresaCuenta
      ref="refDialogCuenta"
      @successResponse="successResponse"
      @errorResponse="errorResponse"
    />
    <EditCuenta
      ref="refDialogEdit"
      :DatosCuenta="cuenta"
      @successResponse="successResponse"
      @errorResponse="errorResponse"
    />
    <SnackbarConfirm
      :messageSnackbar="messageSnackbar"
      @successResponse="openConfirm"
      ref="refSnackbarConfirm"
    ></SnackbarConfirm>
    <SnackbarError
      :messageSnackbar="messageSnackbar"
      @openConfirm="errorResponse"
      ref="refSnackbarError"
    ></SnackbarError>
  </v-container>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import SnackbarConfirm from "../../components/Snackbar/SnackbarConfirm";
import SnackbarError from "../../components/Snackbar/SnackbarError"
import IngresaCuenta from "./dialog/IngresaCuenta";
import EditCuenta from "./dialog/EditarCuenta";
import CuentasImputablesService from "../../service/api/cuentasImputables/index";
import DataTable from "../../components/Datatable/DataTable";
export default {
  components: {
    IngresaCuenta,
    DataTable,
    SnackbarConfirm,
    EditCuenta,
    SnackbarError,
  },
  data() {
    return {
      items: [],
      load: false,
      datos: [
        { codigo: "1", nombre: "CAJA" },
        { codigo: "2", nombre: "BANCO ESTADO" },
      ],
      headers: [
        { text: "Código", value: "cod_largo" },
        { text: "Nombre", value: "nombre_cuenta" },
        { text: "Para toda empresa", value: "empresa" },
        { text: "Tratamiento", value: "tratamiento" },
        { text: "Acción", value: "actions", sortable: false },
      ],
      cuenta: {},
      messageSnackbar: "",
      cod_cuenta: 0,
    };
  },
  methods: {
    successResponse(cod_corto) {
      this.BuscarCuenta(cod_corto);
    },
    errorResponse(response) {
      this.BuscarCuenta(this.cod_cuenta.id);
      alert("Error al crear",response);
    },
    async BuscarCuenta(cod_cuenta) {
      this.load = true;
      let empresa = parseInt(this.$store.state.empresa);
      let response = await CuentasImputablesService.fetchAll(
        cod_cuenta,
        empresa
      );

      this.items = response.map((resp) => {
        if (resp.id_empresa == null) {
          resp.titulo_empresa = "SI";
        } else {
          resp.titulo_empresa = "NO";
        }
        return resp;
      });
      this.cod_cuenta = cod_cuenta;
      this.load = false;
    },
    ingresaAsiento() {
      this.$refs.refDialogAsiento.openDialog = true;
    },
    openAdd() {
      this.$refs.refDialogCuenta.openDialog = true;
    },

    editAsiento(item) {
      this.$refs.refDialogEdit.openDialog = true;
      this.cuenta = item;
    },
    deleteAsiento(item) {
      this.$refs.refSnackbarConfirm.openSnackbar = true;
      this.messageSnackbar = `¿Desea eliminar cuenta: ${item.nombre_cuenta}?`;
      this.cuenta = item;
    },
    async openConfirm() {
      let response = await CuentasImputablesService.destroy(this.cuenta.id);
      if (response.data === 200) {
        this.$refs.refSnackbarConfirm.openSnackbar = false;
        this.successResponse(this.cuenta.cod_corto);
      } else {
        this.$refs.refSnackbarError.openSnackbar = true;
        this.messageSnackbar = `La cuenta ${this.cuenta.nombre_cuenta}, Tiene registros en Libro Diario. No se puede elimimnar!! `;
      }
    },
  },
};
</script>
